@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');


* { 
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
   box-sizing: border-box; 
}
:root {
  --color-primary-dark: #33004C;
  --color-primary-dark-2: #48158A;
  --color-primary-light: #430063;
  --color-primary-light-2: #6D2AC1;
  --color-pink: #F803FE;
  --color-yellow: #FFC107;
}

body {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background:  var(--color-primary-light);   
}

code {
  font-family: 'Press Start 2P', cursive;
}
