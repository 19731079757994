.About {
  display: flex;
  justify-content: center;
  padding: 2rem;

  .Image {
    width: 60%;
    height: auto;
    max-height: 90%;
    max-width: 100%;
  }
}
