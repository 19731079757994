@import "../../mixins.scss";

.Rarity {
  display: grid;
  background: var(--color-primary-light);
  display: flex;
  flex-direction: column;
  align-items: center;

  .Attributes {
    margin-top: 3rem;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .Attribute {
    text-transform: uppercase;
    padding: 1rem;
    background-color: var(--color-primary-dark-2);
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 0 0 147px;
    padding: 20px;
    margin: 10px;

    &.Active {
      color: var(--color-yellow);
    }

    &:hover {
      color: var(--color-yellow);
    }
    
  }

  .Cards {
    margin: 3rem auto 1rem auto;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .Card {
    text-transform: uppercase;
    padding: 0.7rem;
    padding-bottom: 2rem;
    background-color: var(--color-primary-light-2);
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 11rem;
    border: 2px solid #892dff;
    margin: 10px;
    flex: 0 0 176px;

    @include respond(sm) {
      max-width: none;
      height: 24rem;
      width: 100%;
      flex: 0 1 370px;
    }

    img {
      width: 100px;
      height: 100px;

      @include respond(sm) {
        width: 200px;
        height: 200px;
      }
    }
  }

  .Rare {
    margin-right: auto;
    height: 14px;
  }

  .RareStar {
    color: var(--color-yellow);
  }
}
