@import "../../mixins.scss";

.Landing {
  display: grid;
  grid-template-columns: 60% 40%;

  @include respond(sm) {
    grid-template-columns: auto;
  }
  
  background: url(./background.svg);
  background-repeat: no-repeat;
  background-size: cover;

  .Section1 {
    padding-left: 7%;
    padding-top: 13%;
    padding-right: 7%;
  }

  .Title {
    font-size: 24px;
    margin-bottom: 9%;
  }

  .Feature {
    display: flex;
    align-items: center;
    color: black;
  }

  .Feature-Value {
    margin-left: 1rem;
  }

  .Section2 {
    padding-top: 9%;
    padding-right: 10%;
    padding-bottom: 8%;

    @include respond(sm) {
      padding: 3%
    }
  }

  .Card {
    align-items: center;
    text-align: center;
    height: 100%;
    border-radius: 8px;
    background: #6d2ac133;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .Images {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: black;
    width: 100%;
    padding-bottom: 3%;
  }

  .Landing-Btn {
    background: #ffc107;
    color: black;
    padding: 8px;
    width: 85%;
    border: 3px solid #ff9900;
    text-align: center;
    cursor: pointer;
    color: inherit;
    font-family: inherit;
    justify-content: center !important;
  }

  .Mint {
    display: flex;
    align-items: center;
  }

  .MintInput {
    background: #2675bf;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    max-width: 40px;
    text-align: center;
  }

  .Count {
    color: #84ffff;
  }

  .Live {
    color: black;
    margin-top: 2%;
  }

  .BlankImage {
    width: 440px;
    max-height: 100%;
    max-width: 100%;
  }

  .WalletID {
    color: var(--color-pink);
  }
}
