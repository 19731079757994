.FAQ {
  display: grid;
  .Main {
    background:  var(--color-primary-light);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5rem;
  }
  
  .Title {
    font-size: 24px;
    padding: 3rem;
    text-align: center;
  }
  
  .Questions {
    width: 65%;
    background: var(--color-primary-dark);
    font-size: 14px;
  }
  
}
